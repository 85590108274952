import { render, staticRenderFns } from "./rendimiento-combustible.vue?vue&type=template&id=ebfb936c&scoped=true&"
import script from "./rendimiento-combustible.vue?vue&type=script&lang=js&"
export * from "./rendimiento-combustible.vue?vue&type=script&lang=js&"
import style0 from "./rendimiento-combustible.vue?vue&type=style&index=0&id=ebfb936c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ebfb936c",
  null
  
)

export default component.exports
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js"
import QPage from 'quasar/src/components/page/QPage.js'
import QSelect from 'quasar/src/components/select/QSelect.js'
import QImg from 'quasar/src/components/img/QImg.js'
import QSkeleton from 'quasar/src/components/skeleton/QSkeleton.js'
qInstall(component, 'components', {QPage,QSelect,QImg,QSkeleton})
